import React from 'react';

const AppLogo = () => {
    return (
        <>
            <img src="/img/logo.png" alt="LogoText" className="brand-logo" />
            <img src="/img/logo.png" alt="LogoImage" className="brand-logo--mobile" />
            <img src="/img/logo-single.svg" alt="LogoImage" className="brand-logo--collapsed" />
        </>
    );
};

export default AppLogo;
