import React from 'react';

const Dashboard = React.lazy(() => import('@sm360/crm-dashboard'));
const ActivityDashboard = React.lazy(() => import('@sm360/crm-activity-dashboard'));
const DeliveryDashboard = React.lazy(() => import('@sm360/crm-delivery-dashboard'));

const Calendar = React.lazy(() => import('@sm360/crm-calendar'));
const Leads = React.lazy(() => import('@sm360/crm-leads'));
const Deals = React.lazy(() => import('@sm360/crm-deals'));
const Contacts = React.lazy(() => import('@sm360/crm-contacts'));
const Activities = React.lazy(() => import('@sm360/crm-activities'));
const SMSLogs = React.lazy(() => import('@sm360/crm-sms-logs'));
const PhonetrackLogs = React.lazy(() => import('@sm360/crm-phonetrack-logs'));

const DeskingVehicles = React.lazy(() => import('@sm360/crm-desking-vehicles'));
const DeskingQuotes = React.lazy(() => import('@sm360/desking-quotes'));
const DeskingInventoriesReport = React.lazy(() => import('@sm360/desking-inventory-report'));
const DeskingSalesReport = React.lazy(() => import('@sm360/desking-sales-report'));

const Inventories = React.lazy(() => import('@sm360/inventories'));
// const Imports = React.lazy(() => import('@sm360/imports'));

// const Campaign = React.lazy(() => import('@sm360/crm-campaign'));
// const CampaignStatistics = React.lazy(() => import('@sm360/crm-campaign-statistics'));
const Reporting = React.lazy(() => import('@sm360/crm-reporting'));

// const ShowroomDashboard = React.lazy(() => import('@sm360/showroom-dashboard'));

const Settings = React.lazy(() => import('@sm360/crm-settings'));

const Navigation = [
    {
        name: 'Dashboards',
        icon: 'grip',
        submenu: [
            {
                name: 'DRX Dashboard',
                route: '/dashboard',
                component: Dashboard,
                right: 'ModuleReportEnabled',
            },
            {
                name: 'Activity Dashboard',
                translate: 'app.nav.ACTIVITY_DASHBOARD',
                route: '/activity-dashboard',
                component: ActivityDashboard,
                right: 'ModuleReportEnabled',
            },
            {
                name: 'Delivery Dashboard',
                translate: 'app.nav.DELIVERY_DASHBOARD',
                route: '/delivery-dashboard',
                component: DeliveryDashboard,
            },
            // {
            //     name: 'Showroom',
            //     route: '/showroom-dashboard',
            //     component: ShowroomDashboard,
            // },
        ],
    },
    {
        name: 'Calendar',
        translate: 'app.nav.CALENDAR',
        icon: 'calendar-days',
        route: '/calendar',
        component: Calendar,
    },
    {
        name: 'Leads',
        translate: 'app.nav.LEADS',
        icon: 'user-group',
        route: '/leads',
        component: Leads,
        right: 'ModuleLeadEnabled',
    },
    {
        name: 'Contacts',
        translate: 'app.nav.CONTACTS',
        icon: 'address-book',
        route: '/contacts',
        component: Contacts,
        right: 'ContactAccess',
    },
    {
        name: 'Activities',
        translate: 'app.nav.ACTIVITIES',
        icon: 'stopwatch', //'user-clock',
        route: '/activities',
        component: Activities,
    },
    {
        name: 'Quotes',
        translate: 'app.nav.DESKING_QUOTES',
        icon: 'clipboard-list',
        right: ['DeskingAccess', 'CrmDealViewList'],
        submenu: [
            {
                name: 'Quotes',
                translate: 'app.nav.DESKING_QUOTES',
                route: '/desking-quotes',
                component: DeskingQuotes,
                right: 'DeskingAccess',
            },
            {
                name: 'Deals',
                translate: 'app.nav.DEALS',
                route: '/deals',
                component: Deals,
                right: 'CrmDealViewList',
            },
        ],
    },
    {
        name: 'Vehicles',
        icon: 'car',
        submenu: [
            {
                name: 'Inventories',
                translate: 'app.nav.INVENTORIES',
                description: 'app.nav.VEHICLES_DESCRIPTION',
                route: '/inventories/vehicles',
                component: Inventories,
                right: 'XmsDeltaVehicles',
                only: {
                    organizationId: [6314, 30],
                },
            },
            {
                name: 'Vehicles',
                translate: 'app.nav.DESKING_VEHICLES',
                route: '/desking-vehicles',
                right: 'DeskingSearchVehicles',
                component: DeskingVehicles,
            },
        ],
    },
    {
        name: 'Logs',
        translate: 'app.nav.LOGS',
        icon: 'file-lines',
        right: ['PhonetrackAccess', 'CrmSmsNumbersManagement'],
        submenu: [
            {
                name: 'Phonetrack Logs',
                translate: 'app.nav.PHONETRACK_LOGS',
                route: '/phonetrack/logs',
                component: PhonetrackLogs,
                right: 'PhonetrackAccess',
            },
            {
                name: 'SMS Logs',
                translate: 'app.nav.SMS_LOGS',
                route: '/sms/logs',
                component: SMSLogs,
                right: 'CrmSmsNumbersManagement',
            },
        ],
    },
    {
        name: 'Reports',
        translate: 'app.nav.REPORTING',
        icon: 'file-contract',
        right: ['ModuleReportEnabled', 'DeskingAccess'],
        submenu: [
            {
                name: 'Lead Ratios',
                translate: 'app.nav.REPORT_GEN',
                route: '/reporting',
                component: Reporting,
                right: 'ModuleReportEnabled',
            },
            {
                name: 'Inventories Report',
                translate: 'app.nav.DESKING_INVENTORY_REPORT',
                route: '/inventory-report',
                component: DeskingInventoriesReport,
                right: 'DeskingAccess',
            },
            {
                name: 'Sales Report',
                translate: 'app.nav.DESKING_SALES_REPORT',
                route: '/sales-report',
                component: DeskingSalesReport,
                right: 'DeskingAccess',
            },
        ],
    },
    {
        name: 'Campaign',
        icon: 'bullhorn',
        url: '{{REACT_APP_CRM_APP_CAMPAIGN_LEGACY_URL}}',
        right: 'EditCampaign',
    },
    {
        name: 'Configure',
        translate: 'app.nav.CONFIGURE',
        icon: 'cog',
        route: '/settings',
        component: Settings,
        right: [
            'CrmProAdministrationSectionAccess',
            'EditBdcConfiguration',
            'LeadStatusConfigManagement',
            'CrmProEditEmailTemplates',
            'CrmSmsNumbersManagement',
            'IvrEnabled',
            'Admin',
        ],
    },
];

export default Navigation;
